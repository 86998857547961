import React, { Component,Suspense } from 'react';
import Layout from './components/Layout/Layout'
import { Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux'
import * as actions from './store/actions/';

//import Home from './containers/Home/Home'
const Home            = React.lazy(()=>import('./containers/Home/Home'))
const StoreHome       = React.lazy(()=>import('./containers/StoreHome/StoreHome'))
const StoreCategory   = React.lazy(()=>import('./containers/StoreCategory/StoreCategory'))
const StoreProductDetail = React.lazy(()=>import('./containers/Products/StoreProductDetail/StoreProductDetail'))
const Category        = React.lazy(()=>import('./containers/Category/Category'))
const ProductDetail   = React.lazy(()=>import('./containers/Products/ProductDetail/ProductDetail'))
const Cart            = React.lazy(()=>import('./containers/Cart/Cart'))
const Checkout        = React.lazy(()=>import('./containers/Checkout/Checkout'))
const Order           = React.lazy(()=>import('./containers/Order/Order'))
const OrderDetail     = React.lazy(()=>import('./containers/Order/OrderDetail'))
const Myinfo          = React.lazy(()=>import('./containers/Myinfo/Myinfo'))
const Logout          = React.lazy(()=>import('./containers/Auth/Logout/Logout'))

class App extends Component{
  
  componentDidMount(){
    this.props.onTryAutoSignup();
  }

  componentDidUpdate(){
    this.props.onSetCartOnReload(this.props.token);
  }

  render(){

    let routes = (
      <Switch>
        {/* <Route exact path="/"  component={Home} /> */}
        
        <Route exact path="/" render={()=>(
          <Suspense fallback={<div>Loading...</div>} ><Home /></Suspense>
        )} />

        <Route path="/store/:id" render={()=>(
          <Suspense fallback={<div>Loading...</div>} ><StoreHome /></Suspense>
        )} />

        <Route path="/store-category/:id/:store" render={()=>(
          <Suspense fallback={<div>Loading...</div>} ><StoreCategory /></Suspense>
        )} />

        <Route path="/category/:id" render={()=>(
          <Suspense fallback={<div>Loading...</div>} ><Category  /></Suspense>
        )} />

        <Route path="/cart" render={()=>(
          <Suspense fallback={<div>Loading...</div>} ><Cart  /></Suspense>
        )} />

        <Route path="/:id/:store" exact  render={()=>(
          <Suspense fallback={<div>Loading...</div>} ><StoreProductDetail  /></Suspense>
        )} />

        <Route path="/:id" exact  render={()=>(
          <Suspense fallback={<div>Loading...</div>} ><ProductDetail  /></Suspense>
        )} />
        <Redirect to="/" />
      </Switch>
    )

    if(this.props.isAuthenticated){
      routes = (
        <Switch>
          <Route exact path="/" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><Home /></Suspense>
          )} />
          
          <Route path="/store/:id" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><StoreHome /></Suspense>
          )} />
          <Route path="/store-category/:id/:store" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><StoreCategory /></Suspense>
          )} />

          <Route path="/category/:id" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><Category  /></Suspense>
          )} />
  
          <Route path="/cart" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><Cart  /></Suspense>
          )} />

          <Route path="/checkout" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><Checkout  /></Suspense>
          )} />

          <Route exact path="/order" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><Order  /></Suspense>
          )} />

          <Route exact path="/order/:id" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><OrderDetail  /></Suspense>
          )} />


          <Route path="/myinfo" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><Myinfo  /></Suspense>
          )} />
  
          <Route path="/logout" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><Logout  /></Suspense>
          )} />      

          <Route path="/:id/:store" exact  render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><StoreProductDetail  /></Suspense>
          )} /> 

          <Route exact path="/:id" render={()=>(
            <Suspense fallback={<div>Loading...</div>} ><ProductDetail  /></Suspense>
          )} />
          
        </Switch>
      )
      
    }


    return (
      <div>
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}


const mapStateToProps = state =>{
  return {
      token:state.auth.token,
      isAuthenticated: state.auth.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
      onTryAutoSignup: ()=>dispatch(actions.authCheckState()),
      onSetCartOnReload: (token)=>dispatch(actions.cartReload(token))
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App));
