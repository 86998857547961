import React, {Component} from 'react'
import {connect} from 'react-redux'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
// import Card from 'react-bootstrap/Card'
import { Formik, withFormik} from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faKey, faMobile, faUser } from '@fortawesome/free-solid-svg-icons'

import * as storeActions from "../../../store/actions"
import Spinner from '../../../components/UI/Spinner/Spinner'

class Login extends Component{
  
  render(){

        

        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = this.props;

        let loginForm = (
    
            <React.Fragment>
              {/* <Card.Title>User Signup</Card.Title> */}
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="validationFormikName">
                    <Form.Label>Name</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        aria-describedby="inputGroupPrepend"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                    <Form.Label>Phone</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faMobile} /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Phone"
                        aria-describedby="inputGroupPrepend"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        isInvalid={!!errors.phone}
                        maxLength="10"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        aria-describedby="inputGroupPrepend"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                    <Form.Label>Password</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        aria-describedby="inputGroupPrepend"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                
                <Form.Group>
                  <Form.Check
                    required
                    checked={true}
                    name="terms"
                    label={(<a href="https://wholesalecost.in/website/terms-and-conditions" target="_blanck">Agree to terms and conditions</a>)}
                    onChange={handleChange}
                    isInvalid={!!errors.terms}
                    feedback={errors.terms}
                    id="validationFormik0"
                  />
                </Form.Group>
                <Form.Group style={{
                          "width": "100%",
                          "textAlign": "right",
                          "marginBottom": "10px"
                    }} >
                    <a href="javascript:void(0)" onClick={()=>this.props.formSwipeHandler('forgot')} >Forgot password</a>
                </Form.Group>
                <Button type="submit">Submit form</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" size="sm" onClick={()=>this.props.formSwipeHandler('login')} >
                  Switch to Login
                </Button>
                <br />
                <br />
              </Form>
            </React.Fragment>

      );
  

      // console.log(this.props.loading )
       
    if(this.props.loading ==='true'){
        loginForm =(<Spinner/>)
    }

    return (
     <React.Fragment>
        {loginForm}
     </React.Fragment>
    );
  }
}

  const mapStateToProps = state =>{
    return {
        loading: state.auth.loading.toString(),
        autherror: state.auth.error,
    }
  }

  const mapDispatchToProps = dispatch =>{
      return {
          onAuth: (email,phone,password,name)=>dispatch(storeActions.auth(email,phone,password,name)),
          authSuccess: (token)=>dispatch(storeActions.authSuccess(token)),
          authFail: (error)=>dispatch(storeActions.authFail(error)),
      }
  }

  const phoneRegExp = /^\d{10}$/
  const validationSchema = yup.object().shape({
      name: yup.string().required(),
      phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
      email: yup.string().email('Invalid email').required('Required'),
      password: yup.string().required(),
  });

  const MyEnhancedForm = withFormik({
    //mapPropsToValues: () => ({ name: '' }),
    validationSchema, 
    handleSubmit: (values,{ props, resetForm, setErrors, setSubmitting }) => {
      props.onAuth(values.email,values.phone,values.password,values.name)
      .then(response=>{
          localStorage.setItem('token',response.data.token)
          props.authSuccess(response.data.token)
          resetForm();
      }).catch(error=>{
          let errorVal = error.response.data?error.response.data.message:error.message;
          let errorForFormick = {};
          if(errorVal.email && Array.isArray(errorVal.email)){
            errorForFormick["email"] = errorVal.email[0];
          }
          if(errorVal.phone && Array.isArray(errorVal.phone)){
            errorForFormick["phone"] = errorVal.phone[0];
          }

          setErrors({...errorForFormick})

          props.authFail(error.response.data?error.response.data.message:error.message)
      })
    },
  
    displayName: 'BasicForm',
  })(Login);

  export default connect(mapStateToProps,mapDispatchToProps)(MyEnhancedForm);