import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    carts :[],
    weightSlabs:[]
}

const reducer = (state = initialState, action)=>{
    switch(action.type){
        // case actionTypes.ADD_TO_CART:
        //     let findProduct= state.carts.find(cart =>cart.pId === action.cart.pId )
        //     let cartObj = null
        //     if(findProduct===undefined ){
        //         cartObj = {
        //             ...state,
        //             carts: state.carts.concat(action.cart),
        //             error:false
        //         };
        //     }else{
        //         cartObj = {
        //             ...state,
        //             carts: state.carts.map(cart =>cart.pId === action.cart.pId ? {...cart, quantity: parseInt(cart.quantity)+parseInt(action.cart.quantity)} : cart ),
        //             error:false
        //         };
        //     }
        //     localStorage.setItem('carts',JSON.stringify(cartObj.carts));
        //     return cartObj;
        case actionTypes.ADD_TO_CART:
            let cartObj = null
            cartObj = {
                ...state,
                carts: action.cart,
                error:false
            };
            localStorage.setItem('carts',JSON.stringify(cartObj.carts));
            return cartObj;
        case actionTypes.REMOVE_TO_CART:
            //console.log('removecart',state.carts.filter(cart =>cart.pId !== action.pId))
            let cartObjRemove =  {
                ...state,
                carts: state.carts.filter(cart =>cart.pId !== action.pId),
                error:false
            };
            localStorage.setItem('carts',JSON.stringify(cartObjRemove.carts));
            return cartObjRemove;
        // case actionTypes.ADD_QUANTITY_TO_CART:
        //     return {
        //         ...state,
        //         carts: state.carts.map(cart =>cart.pId === action.cart.pId ? {...cart, quantity: cart.quantity+1} : cart ),
        //         error:false
        //     };
        // case actionTypes.REMOVE_QUANTITY_TO_CART:
        //     return {
        //         ...state,
        //         carts: state.carts.map(cart =>cart.pId === action.cart.pId ? {...cart, quantity: cart.quantity-1} : cart ),
        //         error:false
        //     };
        case actionTypes.EMPTY_CART:
            localStorage.removeItem('carts');
            return {
                ...state,
                carts: [],
                error:false
            };
        case actionTypes.WEIGHT_SLAB:
            return {
                ...state,
                weightSlabs: action.weightSlabData,
            };
        default:
            return state;
    }
};

export default reducer;