import React, {Component } from 'react';
import { withRouter } from 'react-router'
// import { Link } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/';
import Aux  from '../../../hoc/Auxs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


class Sidebar extends Component{

    componentDidMount(){
        this.props.onInitCategories();
    }

    moveTocategory(slug){
        this.props.onToggleDrawer()
        this. props.history.push(slug);
    }

    render(){
        let catList = null;
        if(this.props.cats){
                catList = (
                    <Aux>
                        {this.props.cats.map((cat)=>{ 
                                return(
                                    // <Link to={`/category/${ cat.slug }`} key={cat.id} >
                                        <ListGroup.Item style={{
                                            cursor:"pointer"
                                        }} key={cat.id} onClick={()=>this.moveTocategory(`/category/${ cat.slug }`)} aria-label="Toggle navigation" aria-controls="responsive-navbar-nav" ><strong>{ cat.name }</strong></ListGroup.Item>
                                    // </Link>
                                )
                        }) }
                    </Aux>
                )
        }
        return (
            <ListGroup>
                <ListGroup.Item className="bg-primary" style={{
                                            cursor:"pointer",
                                            color:"#fff"
                                        }}  aria-label="Toggle navigation" aria-controls="responsive-navbar-nav" ><strong>CATEGORIES <FontAwesomeIcon onClick={this.props.onToggleDrawer} icon={faTimes} className="float-right" /> </strong></ListGroup.Item>
                {catList}
            </ListGroup>
        )
    }
}


const mapStateToProps = state =>{
    return {
        cats: state.category.categories
    }
}

const mapDispatchToProps = dispatch=>{
    return {
        onInitCategories: ()=>dispatch(actions.initCategories()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Sidebar));