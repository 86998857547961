import React, { Component } from 'react'
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Formik } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile } from '@fortawesome/free-solid-svg-icons'
import * as storeActions from "../../../store/actions"
import Spinner from '../../../components/UI/Spinner/Spinner'
import axios from '../../../axios/axios';
class Forgot extends Component {

    state = {
        success: true,
        message: null
    }

    render() {

        const schema = yup.object().shape({
            phone: yup.string().required('Required'),
        });

        let loginForm = (
            <Formik
                validationSchema={schema}
                onSubmit={(values, actions) => {
                    console.log(values.phone);
                    axios.post('/forgotPassword', { phone: values.phone }).then(response => {
                        if (response.data.isUser) {
                            this.setState({
                                ...this.state,
                                success: true,
                                message: response.data.message
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                success: false,
                                message: response.data.message
                            });
                        }
                    }).catch(error => {
                        console.log("error forget", error);
                        this.setState({
                            ...this.state,
                            success: false,
                            message: error.message
                        });
                    })
                    actions.setSubmitting(false);

                }}
                initialValues={{
                    email: '',
                    password: '',
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <React.Fragment>
                        <Card.Title style={{
                            color: this.state.success ? "green" : 'red'
                        }}>{this.state.message}</Card.Title>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                                    <Form.Label>Phone</Form.Label>
                                    <InputGroup hasValidation>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faMobile} /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone"
                                            aria-describedby="inputGroupPrepend"
                                            name="phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                            isInvalid={!!errors.phone}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                            </Form.Row>

                            <Form.Group style={{
                                marginBottom: "30px",
                                paddingTop: "20px"
                            }}>
                                <Form.Check
                                    required
                                    checked={true}
                                    name="terms"
                                    label={(<a href="https://wholesalecost.in/website/terms-and-conditions" target="_blanck">Agree to terms and conditions</a>)}
                                    onChange={handleChange}
                                    isInvalid={!!errors.terms}
                                    feedback={errors.terms}
                                    id="validationFormik0"
                                />
                            </Form.Group>
                            <Button type="submit">Submit form</Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant="secondary" size="sm" onClick={() => this.props.formSwipeHandler('login')} >
                                Switch to Login
                            </Button>
                            <br />
                            <br />
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        );


        // console.log(this.props.loading )

        if (this.props.loading === 'true') {
            loginForm = (<Spinner />)
        }

        return (
            <React.Fragment>
                {loginForm}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading.toString()
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(storeActions.auth(email, null, password, null)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);