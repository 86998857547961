import * as actionTypes from '../actions/actionsTypes';
import {updateObject} from '../utility'
const initialState = {
    myinfo:null,
    token :null,
    error:null,
    loading:false,
    show:false,
}

const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.AUTH_START:
            return updateObject(state, {error: null ,loading : true });
        case actionTypes.AUTH_SUCCESS:
            return updateObject(state, {
                token:action.token,
                error: null ,
                loading : false,
                show:false,
             });
        case actionTypes.AUTH_FAIL:
            return updateObject(state, {
                error:action.error,
                loading : false });
        case actionTypes.FETCH_MYINFO:
            return updateObject(state, { myinfo:action.myinfoData });
        case actionTypes.AUTH_LOGOUT:
            return updateObject(state, { token:null, });
        case actionTypes.STORE_MYINFO_START:
            return updateObject(state, {error: null ,loading : true });
        case actionTypes.STORE_MYINFO:
            return updateObject(state, {error: null ,loading : false,myinfo:action.myinfoData });
        default:
            return state;
    }
};

export default reducer;