import React, { Component } from 'react'
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Formik } from 'formik';
import * as yup from 'yup';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faMobile } from '@fortawesome/free-solid-svg-icons'

import * as storeActions from "../../../store/actions"
import Spinner from '../../../components/UI/Spinner/Spinner'

class Login extends Component {

  render() {

    const phoneRegExp = /^\d{10}$/
    const schema = yup.object().shape({
      // email: yup.string().email('Invalid email').required('Required'),
      phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
      password: yup.string().required(),
    });

    let loginForm = (
      <Formik
        validationSchema={schema}
        onSubmit={(values, actions) => {
          this.props.onAuth(values.phone, values.password).then(response => {
            localStorage.setItem('token', response.data.token)
            this.props.authSuccess(response.data.token)
            actions.resetForm();
          }).catch(error => {
            let errorVal = error.response.data ? error.response.data.message : error.message;
            swal(errorVal, "", "error");
            this.props.authFail(error.response.data ? error.response.data.message : error.message)
          })
          actions.setSubmitting(false);
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   actions.setSubmitting(false);
          // }, 1000);
        }}
        initialValues={{
          phone: '',
          password: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <React.Fragment>
            {/* <Card.Title>User Login</Card.Title> */}
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                  <Form.Label>Phone</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faMobile} /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      aria-describedby="inputGroupPrepend"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationFormikUsername">
                  <Form.Label>Password</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      aria-describedby="inputGroupPrepend"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Check
                  required
                  checked={true}
                  name="terms"
                  label={(<a href="https://wholesalecost.in/website/terms-and-conditions" target="_blanck">Agree to terms and conditions</a>)}
                  onChange={handleChange}
                  isInvalid={!!errors.terms}
                  feedback={errors.terms}
                  id="validationFormik0"
                />
              </Form.Group>
              <Form.Group style={{
                "width": "100%",
                "textAlign": "right",
                "marginBottom": "10px"
              }} >
                <a href="#" onClick={() => this.props.formSwipeHandler('forgot')} >Forgot password</a>
              </Form.Group>

              <Button type="submit">Submit form</Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="secondary" size="sm" onClick={() => this.props.formSwipeHandler('Signup')} >
                Switch to Signup
              </Button>
              <br />
              <br />
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );


    // console.log(this.props.loading )

    if (this.props.loading === 'true') {
      loginForm = (<Spinner />)
    }

    return (
      <React.Fragment>
        {loginForm}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading.toString()
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (phone, password) => dispatch(storeActions.auth(null, phone, password, null)),
    authSuccess: (token) => dispatch(storeActions.authSuccess(token)),
    authFail: (error) => dispatch(storeActions.authFail(error)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);