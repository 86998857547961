import * as actionTypes from './actionsTypes'
import axios from '../../axios/axios';

export const onAddToCart = (cart) => {
    return {
        type: actionTypes.ADD_TO_CART,
        cart: cart
    }
}


export const onRemoveToCart = (pId) => {
    return {
        type: actionTypes.REMOVE_TO_CART,
        pId: pId
    }
}

// export const addQuantityToCart = (pId)=>{
//     return {
//         type: actionTypes.ADD_QUANTITY_TO_CART,
//         pId:pId
//     }
// }

// export const removeQuantityToCart = (pId)=>{
//     return {
//         type: actionTypes.REMOVE_QUANTITY_TO_CART,
//         pId:pId
//     }
// }

export const onEmptyCart = () => {
    return {
        type: actionTypes.EMPTY_CART,
    }
}

export const addToCart = (carts, token) => {
    return dispatch => {
        //dispatch(onAddToCart(carts))
        axios.post('/cart-add', { ...carts }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            let cartsRes = response.data
            console.log('cart api response', cartsRes);

            dispatch(onAddToCart(cartsRes.carts))
        }).catch(error => {
            console.log(error)
        })
    }
}

export const removeToCart = (pId, token) => {
    return dispatch => {
        //dispatch(onAddToCart(carts))
        axios.post('/cart-remove', { pId: pId }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            let cartsRes = response.data
            console.log('cart api response', cartsRes);

            dispatch(onAddToCart(cartsRes.carts))
        }).catch(error => {
            console.log(error)
        })
    }
}

export const emptyCart = (token) => {
    return dispatch => {
        //dispatch(onAddToCart(carts))
        axios.get('/cart-empty', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            let cartsRes = response.data
            console.log('cart api response', cartsRes);

            dispatch(onAddToCart(cartsRes.carts))
        }).catch(error => {
            console.log(error)
        })
    }
}

export const cartReload = (token) => {
    return dispatch => {
        axios.get('/cart-get', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            let cartsRes = response.data
            console.log('cart api response', cartsRes);

            dispatch(onAddToCart(cartsRes.carts))
        }).catch(error => {
            console.log(error)
        })
        // const carts = JSON.parse(localStorage.getItem('carts'))
        // if(carts){
        //     dispatch(onAddToCart(carts))
        // }
    }
}


export const weightSlabSuccess = (weightSlabData) => {
    return {
        type: actionTypes.WEIGHT_SLAB,
        weightSlabData: weightSlabData
    }
}

export const weightSlab = () => {
    return dispatch => {
        axios.get('/weight-slab').then(response => {
            dispatch(weightSlabSuccess(response.data.data))
        }).catch(error => {
            console.log(error)
        })
    }
}



