import React, { useState } from 'react';
import { withRouter } from 'react-router'
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { connect } from 'react-redux'
import Auth from '../../../containers/Auth/Auth'
import Sidebar from '../Sidebar/Sidebar'
import './Toolbar.css'
import * as actions from '../../../store/actions';
import imgDown from '../../../assets/icon.jpg'


const Toolbar = (props) => {

    // const location = useLocation();

    let quqntityCount = props.carts.length > 0 ? props.carts.reduce((a, b) => parseInt(a) + (parseInt(b['quantity']) || 0), 0) : null

    const [modalShow, setModalShow] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const redirectTo = (link) => {
        props.history.push('/' + link);
    }

    const handleChange = (event) => {
        setSearchValue(event.target.value);
        if (event.key === 'Enter') {
            props.onProductSearchReset();
            props.history.push({
                pathname: '/',
                search: '?q=' + searchValue
            })
            return;
        }

        if (searchValue.length > 1) {
            props.onProductSearch(searchValue)
        }

    }

    const moveToProductDetail = (slug) => {
        props.onProductSearchReset();
        props.history.push('/' + slug)
    }

    let modal = null;
    if (!props.isAuthenticated) {
        modal = (<Auth show={modalShow} onHide={() => setModalShow(false)} ></Auth>)
    }


    return (
        <React.Fragment>
            {modal}
            <header className="desktopNav" >
                <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
                    <Button className="mr-2" type="button" onClick={() => props.onToggleDrawer()} ><FontAwesomeIcon icon={faBars} /> Category</Button>
                    <Link to="/"><Navbar.Brand >WholesaleCost.in</Navbar.Brand></Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto" style={{ width: '80%' }}>
                            <div inline="true" style={{ width: '70%' }}  >

                                <FormControl type="text" placeholder="Search" className="mr-sm-2" style={{ width: '70%', float: "left" }}
                                    value={searchValue}
                                    onChange={handleChange}
                                    onKeyDown={handleChange}
                                    onKeyUp={handleChange}
                                />

                                <ul className="list-group-custom">
                                    {
                                        props.products ? props.products.map(product => {
                                            return (<li key={product.id} onClick={() => moveToProductDetail(product.slug)} className="list-group-item-custom" >{product.name}</li>)
                                        }) : null
                                    }
                                </ul>
                                <a href="https://play.google.com/store/apps/details?id=com.wholesalecost" target="_blank" style={{ width: '20%' }} ><Image src={imgDown} style={{ width: '60px' }} /></a>

                                {/* <Button variant="outline-light" style={{width:'20%'}} >Search</Button> */}
                            </div>
                            <h5 className="mt-2 text-white" ><a className=" text-white" href="https://wholesalecost.in/manage/public/admin/register" >Seller Portal</a> </h5>
                            {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
                        </Nav>

                        <Nav>
                            <NavDropdown title="My Account" id="collasible-nav-dropdown" style={{ color: "#fff" }} >
                                {!props.isAuthenticated ? <NavDropdown.Item onClick={() => setModalShow(true)}>Login</NavDropdown.Item>
                                    : (
                                        <React.Fragment>
                                            <NavDropdown.Item onClick={() => redirectTo('logout')} >Logout</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('order')} >Order</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => redirectTo('myinfo')} >MyInfo</NavDropdown.Item>
                                        </React.Fragment>
                                    )}
                                {/* <NavDropdown.Divider /> */}
                            </NavDropdown>
                            <Nav.Link onClick={() => redirectTo('cart')} eventKey={2} style={{ color: "#fff" }} >
                                Cart <FontAwesomeIcon icon={faShoppingCart} />
                                <Badge variant="danger" className="ml-2" >{quqntityCount}</Badge>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
            <header className="mobileNav">
                <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
                    <div className="topBar">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => props.onToggleDrawer()} />


                        {/* <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            <Sidebar />
                        </Nav>
                    </Navbar.Collapse> */}

                        <p className=" text-white" >
                            <Link to="/">
                                <Navbar.Brand >WholesaleCost.in</Navbar.Brand>
                            </Link>
                            <br />
                            <a className=" text-white" href="https://wholesalecost.in/manage/public/admin/register" >Seller Portal</a>
                        </p>
                        <NavDropdown title="" id="collasible-nav-dropdown" style={{ color: "#fff" }} >
                            {!props.isAuthenticated ? <NavDropdown.Item onClick={() => setModalShow(true)}>Login</NavDropdown.Item>
                                : (
                                    <React.Fragment>
                                        <NavDropdown.Item onClick={() => redirectTo('logout')} >Logout</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => redirectTo('order')} >Order</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => redirectTo('myinfo')} >MyInfo</NavDropdown.Item>
                                    </React.Fragment>
                                )}
                            {/* <NavDropdown.Divider /> */}
                        </NavDropdown>
                        {/* <FontAwesomeIcon className="shoppingCart" icon={faShoppingCart} /> */}
                        <Nav.Link onClick={() => redirectTo('cart')} eventKey={2} style={{ color: "#fff" }} >
                            <FontAwesomeIcon className="shoppingCart" icon={faShoppingCart} />
                            <Badge variant="danger" className="ml-2" >{quqntityCount}</Badge>
                        </Nav.Link>
                    </div>
                    <div className="searchBox">
                        <div inline="true" style={{ width: '100%' }}  >

                            <FormControl type="text" placeholder="Search" className="mr-sm-2" style={{ width: '75%', float: "left" }}
                                value={searchValue}
                                onChange={handleChange}
                                onKeyDown={handleChange}
                            />
                            <FontAwesomeIcon className="searchIcon" icon={faSearch} />

                            <ul className="list-group-custom" style={{
                                width: '70%'
                            }}>
                                {
                                    props.products ? props.products.map(product => {
                                        return (<li key={product.id} onClick={() => moveToProductDetail(product.slug)} className="list-group-item-custom" >{product.name}</li>)
                                    }) : null
                                }
                            </ul>
                            <a href="https://play.google.com/store/apps/details?id=com.wholesalecost" target="_blank" style={{ width: '20%', marginLeft: 10 }} ><Image src={imgDown} style={{ width: '60px', marginTop: 15 }} /></a>

                            {/* <Button variant="outline-light" style={{width:'20%'}} >Search</Button> */}
                        </div>
                        {/* <Form>
							<FormControl type="text" placeholder="Search" />
							<FontAwesomeIcon className="searchIcon" icon={faSearch} />
						</Form> */}
                    </div>

                </Navbar>
            </header>
        </React.Fragment>
    )
}


const mapStateToProps = state => {
    return {
        carts: state.cart.carts,
        isAuthenticated: state.auth.token !== null,
        products: state.product.productSearch,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onProductSearch: (name) => dispatch(actions.productSearch(name)),
        onProductSearchReset: () => dispatch(actions.productSearchReset()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Toolbar));