export const REQUEST_START = 'REQUEST_START';
export const LIST_CATEGORIES = 'LIST_CATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';
export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const PRODUCT_BY_ID = 'PRODUCT_BY_ID';
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
export const PRODUCT_SEARCH_RESET = 'PRODUCT_SEARCH_RESET';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';
export const INIT_PRODUCTS_HOME = 'INIT_PRODUCTS_HOME';
export const INITIAL_PRODUCTS_HOME = 'INITIAL_PRODUCTS_HOME';
export const SET_PRODUCTS_HOME = 'SET_PRODUCTS_HOME';
export const FETCH_PRODUCTS_HOME_FAILED = 'FETCH_PRODUCTS_HOME_FAILED';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_TO_CART = 'REMOVE_TO_CART';
export const ADD_QUANTITY_TO_CART = 'ADD_QUANTITY_TO_CART';
export const REMOVE_QUANTITY_TO_CART = 'REMOVE_QUANTITY_TO_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const INIT_PRODUCTS = 'INIT_PRODUCTS';
export const SET_ORDERS = 'SET_ORDERS';
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';
export const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL';
export const FETCH_ORDER_DETAIL_FAILED = 'FETCH_ORDER_DETAIL_FAILED';
export const FETCH_MYINFO = 'FETCH_MYINFO';
export const STORE_MYINFO_START = 'STORE_MYINFO_START';
export const STORE_MYINFO = 'STORE_MYINFO';
export const WEIGHT_SLAB = 'WEIGHT_SLAB';