import * as actionTypes from './actionsTypes'
import axios from '../../axios/axios';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    }
}
export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token')
    return {
        type: actionTypes.AUTH_LOGOUT,
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token')
        if (!token) {
            dispatch(logout())
        } else {
            dispatch(authSuccess(token))
        }
    }
}

export const myinfoSuccess = (myinfoData) => {
    return {
        type: actionTypes.FETCH_MYINFO,
        myinfoData: myinfoData
    }
}

export const storeMyinfoStart = () => {
    return {
        type: actionTypes.STORE_MYINFO_START,
    }
}


export const storeMyinfoSuccess = (myinfoData) => {
    return {
        type: actionTypes.STORE_MYINFO,
        myinfoData: myinfoData
    }
}


export const auth = (email, phone, password, name) => {
    return dispatch => {
        dispatch(authStart());
        if (name) {
            return axios.post('/register', { email: email, phone: phone, password: password, name: name })
            // .then(response=>{
            //     localStorage.setItem('token',response.data.token)
            //     dispatch(authSuccess(response.data.token))
            // }).catch(error=>{
            //     console.log("auth error",error.response.data.message);
            //     dispatch(authFail(error.response.data?error.response.data.message:error.message))
            // })

        } else {
            return axios.post('/login', { phone: phone, password: password })
            // .then(response=>{
            //     localStorage.setItem('token',response.data.token)
            //     dispatch(authSuccess(response.data.token))
            // }).catch(error=>{
            //     console.log("auth error",error.response.data.message);
            //     dispatch(authFail(error.response.data?error.response.data.message:error.message))
            // })
        }

    }
}

export const myinfo = (token) => {
    return dispatch => {
        axios.get('/myinfo', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch(myinfoSuccess(response.data.data))
        }).catch(error => {
            console.log(error)
        })
    }
}


export const storeMyinfo = (email, phone, name, address, gst, token) => {
    return dispatch => {
        dispatch(storeMyinfoStart());
        axios.post('/storemyinfo', {
            email: email, phone: phone, name: name, address: address, gst: gst
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            dispatch(storeMyinfoSuccess(response.data.data))
        }).catch(error => {
            console.log(error)
        })
    }
}





