import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    orders :null,
    error:false,
    orderDetail :null,
    errorDetail:false,
}

const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.FETCH_ORDERS_FAILED:
            return{
                ...state,
                error:true
            };
        case actionTypes.SET_ORDERS:
            return{
                ...state,
                orders:action.orders,
                error:false
            };
        case actionTypes.FETCH_ORDER_DETAIL_FAILED:
            return{
                ...state,
                errorDetail:true
            };
        case actionTypes.SET_ORDER_DETAIL:
            return{
                ...state,
                orderDetail:action.orderDetail,
                error:false
            };
        default:
            return state;
    }
};

export default reducer;