import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'


import Login from './Login/Login'
import Signup from './Signup/Signup'
import Forgot from './Forgot/Forgot'

class Modals extends Component {
  state = {
    loginForm: true
  }

  onFormSwipeHandler = (type) => {
    this.setState({
      ...this.state,
      loginForm: type
    })
  }

  onModalCloseHandler = () => {
    this.setState({ show: false });
  }

  render() {
    let authForm = null;
    let authHeader = null;
    if (this.state.loginForm === "Signup") {
      authForm = (<Signup formSwipeHandler={(type) => this.onFormSwipeHandler(type)} />);
      authHeader = "Signup Form";
    } else if (this.state.loginForm === "forgot") {
      authForm = (<Forgot formSwipeHandler={(type) => this.onFormSwipeHandler(type)} />);
      authHeader = "Forgot Password Form";
    } else {
      authForm = (<Login formSwipeHandler={(type) => this.onFormSwipeHandler(type)} />);
      authHeader = "Login Form";
    }
    return (
      <Modal {...this.props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" onClick={this.onModalCloseHandler}>
            {authHeader}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <Card className="text-center" style={{ height: '100%' }} >
                  <Card.Body>
                    {authForm}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }
}


export default Modals;