import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    productSearch  : null,
    product  : null,
    productHome : null,
    similerData:[],
    products : [],
    totalPage:null,
    loading:false,
    error:false,
}

const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.REQUEST_START:
            return{
                ...state,
                loading:true,
                error:false
            };
        case actionTypes.INIT_PRODUCTS:
            return{
                ...state,
                products:action.products,
                totalPage:action.totalPage,
                loading:false,
                error:false
            };
        case actionTypes.SET_PRODUCTS:
            return{
                ...state,
                products:state.products.concat(action.products),
                totalPage:action.totalPage,
                loading:false,
                error:false
            };
        case actionTypes.PRODUCT_BY_ID:
            return{
                ...state,
                product:action.product,
                similerData:action.similerData,
                loading:false,
                error:false
            };
        case actionTypes.PRODUCT_SEARCH:
            return{
                ...state,
                productSearch:action.products,
                loading:false,
                error:false
            };
        case actionTypes.PRODUCT_SEARCH_RESET:
            return{
                ...state,
                productSearch:null,
                loading:false,
                error:false
            };
        case actionTypes.FETCH_PRODUCTS_FAILED:
            return{
                ...state,
                loading:false,
                error:true
            };
        case actionTypes.INIT_PRODUCTS_HOME:
            return{
                ...state,
                loading:true,
                error:false
            };
        case actionTypes.INITIAL_PRODUCTS_HOME:
            return{
                ...state,
                productHome:action.productHome,
                totalPage:action.totalPage,
                loading:false,
                error:false
            };
        case actionTypes.SET_PRODUCTS_HOME:
            return{
                ...state,
                productHome:state.productHome.concat(action.productHome),
                totalPage:action.totalPage,
                loading:false,
                error:false
            };
        case actionTypes.FETCH_PRODUCTS_HOME_FAILED:
            return{
                ...state,
                loading:false,
                error:true
            };
        default:
            return state;
    }
};

export default reducer;