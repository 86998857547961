import * as actionTypes from '../actions/actionsTypes';
import axios from '../../axios/axios';

export const setProducts = (products, totalPage) => {
    return {
        type: actionTypes.SET_PRODUCTS,
        products: products,
        totalPage: totalPage,
    }
}


export const requestStart = () => {
    return {
        type: actionTypes.REQUEST_START,
    }
}

export const initProductsState = (products, totalPage) => {
    return {
        type: actionTypes.INIT_PRODUCTS,
        products: products,
        totalPage: totalPage
    }
}

export const initProductById = (product, similerData) => {
    return {
        type: actionTypes.PRODUCT_BY_ID,
        product: product,
        similerData: similerData
    }
}

export const initProductSearch = (products) => {
    return {
        type: actionTypes.PRODUCT_SEARCH,
        products: products
    }
}

export const productSearchReset = () => {
    return {
        type: actionTypes.PRODUCT_SEARCH_RESET,
    }
}

export const fetchProductsFailed = () => {
    return {
        type: actionTypes.FETCH_PRODUCTS_FAILED,
    }
}

export const initProductsHomeState = () => {
    return {
        type: actionTypes.INIT_PRODUCTS_HOME,
    }
}

export const initialProductHomeState = (productHome, totalPage) => {
    return {
        type: actionTypes.INITIAL_PRODUCTS_HOME,
        productHome: productHome,
        totalPage: totalPage
    }
}
export const setProductHome = (productHome, totalPage) => {
    return {
        type: actionTypes.SET_PRODUCTS_HOME,
        productHome: productHome,
        totalPage: totalPage,
    }
}

export const setProductsHomeFailed = () => {
    return {
        type: actionTypes.FETCH_PRODUCTS_HOME_FAILED,
    }
}

export const initProducts = (initData) => {
    console.log("initData", initData);
    return dispatch => {
        dispatch(requestStart());
        axios.get('/products', initData).then(response => {
            if (initData.params.page === 1) {
                //console.log('first line called 1');
                dispatch(initProductsState(response.data.data, response.data.totalPage))
            } else {
                //console.log('second line called 2');
                dispatch(setProducts(response.data.data, response.data.totalPage))
            }

        }).catch(error => {
            dispatch(fetchProductsFailed())
        })
    }
}

export const getProductHome = (initData) => {
    console.log("initData p", initData);
    return dispatch => {
        dispatch(initProductsHomeState());
        axios.get('/home-products', initData).then(response => {
            if (initData.params.page === 1) {
                //console.log('first line called 1');
                dispatch(initialProductHomeState(response.data.data, response.data.totalPage))
            } else {
                //console.log('second line called 2');
                dispatch(setProductHome(response.data.data, response.data.totalPage))
            }
        }).catch(error => {
            dispatch(setProductsHomeFailed())
        })
    }
}

export const productById = (data) => {
    return dispatch => {
        axios.get('/product', { params: data }).then(response => {
            dispatch(initProductById(response.data.data, response.data.similerData));
        }).catch(error => {
            dispatch(fetchProductsFailed())
        })
    }
}

export const productSearch = (name) => {
    return dispatch => {
        axios.get('/product-search', { params: { name: name } }).then(response => {
            dispatch(initProductSearch(response.data.data));
        }).catch(error => {
            dispatch(fetchProductsFailed())
        })
    }
}

