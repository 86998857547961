import React, { useState } from 'react';
import Aux from '../../hoc/Auxs'
import Toolbar from '../Navigation/Toolbar/Toolbar'
import Sidebar from '../Navigation/Sidebar/Sidebar'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Drawer from '@material-ui/core/Drawer';

function Layout(props){

    const [isdroewr, setDrower] = useState(false);

    const toggleDrawer = (open)=>(event)=>{
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }
    
        setDrower(open);
    };

    return(<Aux>
        <Toolbar onToggleDrawer={toggleDrawer(true)}  />
        <Container as="div" fluid style={{ marginTop:5}} >
            <Row>
                {/* <Col lg={2} md={2} className="d-none d-lg-block" >
                    <Sidebar />
                </Col> */}
                <Drawer anchor='left' open={isdroewr} onClose={toggleDrawer(false)}>
                    <Sidebar onToggleDrawer={toggleDrawer(false)} />
                </Drawer>
                <Col lg={12} md={12}>
                    {props.children}
                </Col>
            </Row>
        </Container>
        {/* <main>
            
        </main> */}
    </Aux>)
}

export default Layout